// src/Navbar.js
import React from 'react';

const DownNavbar = () => {
  return (
    <div className="flex justify-between items-center px-6 py-4 bg-white shadow-md">
      {/* Logo Section */}
      <div className="flex items-center">
        <img
          src="https://images.unsplash.com/photo-1700718008794-3db41f8d6c16?q=80&w=1086&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" // Replace with your logo image path
          alt="Hyperlink InfoSystem Logo"
          className="h-12 w-auto"
        />
      </div>

      {/* Navigation Links */}
      <div className="flex space-x-8">
        <div className="flex space-x-4 text-sm text-gray-800">
        <div className="flex items-center space-x-6">
        <div className="flex items-center space-x-2">
          <img
            src="https://plus.unsplash.com/premium_photo-1674591172888-1184c4170a47?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" // Replace with your flag image path
            alt="India"
            className="w-5 h-5 rounded-full"
          />
          <span className="text-sm text-gray-700">+91 9671966994</span>
        </div>
        <div className="flex items-center space-x-2">
          <img
            src="https://plus.unsplash.com/premium_photo-1674591172888-1184c4170a47?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" // Replace with your flag image path
            alt="India"
            className="w-5 h-5 rounded-full"
          />
          <span className="text-sm text-gray-700">+91 9818604556</span>
        </div>
        <div className="flex items-center space-x-2">
          <img
            src="https://plus.unsplash.com/premium_photo-1674591172888-1184c4170a47?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" // Replace with your flag image path
            alt="India"
            className="w-5 h-5 rounded-full"
          />
          <span className="text-sm text-gray-700">+91 9996642344</span>
        </div>
      </div>
        </div>
      </div>

      {/* Call to Action Button */}
      <div className="flex">
        <button className="bg-purple-600 text-white px-6 py-2 rounded-full hover:bg-purple-800 text-sm font-medium">
          Contact Us
        </button>
      </div>
    </div>
  );
};

export default DownNavbar;
