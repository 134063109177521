import React from 'react';

const BusinessSolutions = () => {
  return (
    <div className="flex items-center justify-between min-h-80 bg-gray-50 px-6 mt-[-40px]">
      {/* Left Side: Main Heading */}
      <div className="max-w-lg">
        <h1 className="text-5xl font-bold text-gray-900">
          Amplifying Business Progress Through Smart Solutions
        </h1>
      </div>

      {/* Right Side: Subheading and Button */}
      <div className="text-right max-w-md">
        <p className="text-lg text-gray-600 mb-8">
          Obtain robust software solutions, modernize systems, and leverage futuristic technologies for growth opportunities with the capabilities of a leading development company.
        </p>
        
      </div>
    </div>
  );
};

export default BusinessSolutions;

