import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-black text-white py-12">
      <div className="container mx-auto px-4 md:px-8">
        {/* Top Section: Logo & Social Media */}
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          {/* Logo and Description */}
          <div className="space-y-4">
            <img
              src="path_to_logo" // Replace with your logo path
              alt="Micart Solution Pvt"
              className="h-12"
            />
            <span>Micart solution pvt Ltd.</span>
            <p className="text-gray-400 max-w-xs">
              Top IT Consulting Company Delivering Custom Innovative Solutions
            </p>
          </div>

          {/* Social Media Section */}
          <div className="text-center">
           
          </div>
        </div>

        {/* Bottom Section: Links */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mt-12">
          {/* About Section */}
          <div>
            <h3 className="text-lg font-bold mb-4">About</h3>
            <ul className="space-y-2">
            <li>Privacy Policy</li>
              <li>Contact Us</li>
            </ul>
          </div>

          {/* Mobile App Section */}
          <div>
            <h3 className="text-lg font-bold mb-4">Mobile App</h3>
            <ul className="space-y-2">
              <li>Android Apps</li>
              <li>iPhone Apps</li>
              <li>Hybrid Apps</li>
              <li>Flutter</li>
              <li>React Native</li>
              <li>Kotlin</li>
              <li>Ionic</li>
              <li>Swift</li>
              <li>Xamarin</li>
            </ul>
          </div>

          {/* Web Section */}
          <div>
            <h3 className="text-lg font-bold mb-4">Web</h3>
            <ul className="space-y-2">
              <li>PHP</li>
              <li>Java</li>
              <li>Python</li>
              <li>WordPress</li>
              <li>Drupal</li>
              <li>Laravel</li>
              <li>CodeIgniter</li>
              <li>CakePHP</li>
              <li>TypeScript</li>
            </ul>
          </div>

          {/* Ecommerce Section */}
          <div>
            <h3 className="text-lg font-bold mb-4">Ecommerce</h3>
            <ul className="space-y-2">
              <li>Magento</li>
              <li>Shopify</li>
              <li>Ubercart</li>
              <li>Prestashop</li>
              <li>CS Cart</li>
              <li>VirtueMart</li>
              <li>BigCommerce</li>
              <li>WooCommerce</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
