import React, { useState, useEffect } from 'react';

const slides = [
  {
    title: 'Web Development Company',
    rank: '# Top Web Development Company in USA and India',
    description: 'Drop Your Queries',
    image: 'https://i.pinimg.com/564x/9c/84/72/9c8472d7110ad43b49d81c69ea1bf532.jpg', // Example image URL
  },
  {
    title: 'Blockchain Development Company',
    rank: '# Leader in Blockchain Consulting in USA and India',
    description: 'Expert Consulting Solutions',
    image: 'https://images.unsplash.com/photo-1614787913553-8aa43d6fc7f5?q=80&w=1771&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    title: 'Artificial Development Company',
    rank: '# Provider for AI Solutions',
    description: 'Secure, Scalable AI Solutions',
    image: 'https://www.nibib.nih.gov/sites/default/files/inline-images/AI%20600%20x%20400.jpg',
  },
];

const HeroSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to go to the next slide
  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  // Function to go to the previous slide
  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  // Automatically slide every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 3000); // 3000ms = 3s

    return () => {
      clearInterval(interval); // Cleanup the interval on component unmount
    };
  }, [currentIndex]); // Re-run the effect whenever the currentIndex changes

  return (
    <div className="relative w-full h-screen overflow-hidden bg-white">
      {/* Stylish Background */}
      <div className="absolute inset-0 z-0 bg-gradient-to-r from-white to-gray-300 opacity-70"></div>
      <div className="absolute inset-0 z-0">
        {/* Indian Flag Colored Diagonal Lines */}
        <svg className="h-full w-full opacity-30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 600" preserveAspectRatio="none">
          {/* Saffron Lines */}
          <path fill="none" stroke="#FF9933" strokeWidth="4" d="M0 50L800 450M0 150L800 550M0 250L800 650" />
          {/* Green Lines */}
          <path fill="none" stroke="#138808" strokeWidth="4" d="M0 100L800 500M0 200L800 600M0 300L800 700" />
        </svg>
      </div>

      {/* Slider Content */}
      <div
        className="relative z-10 w-full h-full flex transition-transform duration-500"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div key={index} className="w-full flex-shrink-0 flex items-center justify-center h-full">
            {/* Left: Text Section */}
            <div className="w-1/2 p-8 flex items-start justify-center flex-col">
              {/* Main Heading */}
              <h1 className="text-6xl font-bold text-red-400">
                {slide.title.split(' ')[0]}
              </h1>
              <h2 className="text-4xl font-light text-black">
                {slide.title.split(' ').slice(1).join(' ')}
              </h2>

              {/* Rank in one line */}
              <div className="mt-4 flex items-center space-x-2">
                <p className="text-6xl font-extrabold text-red-400">#</p>
                <p className="text-xl font-medium text-gray-700">
                  Ranked as <span className="text-red-400 font-bold">{slide.rank}</span>
                </p>
              </div>

              {/* Description */}
              <div className="mt-4 flex items-center space-x-2">
                {/* Icon */}
                <div className="w-8 h-8 rounded-full border border-black flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6 text-black"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 10h2l2-4 6 8 4-4 3 6H2z"
                    />
                  </svg>
                </div>

                {/* Description Text */}
                <p className="text-xl font-medium text-gray-700">{slide.description}</p>
              </div>

              {/* Contact Us Button */}
              <button className="mt-6 px-6 py-3 bg-blue-600 text-white text-lg font-medium rounded-lg shadow hover:bg-blue-700">
                Contact Us
              </button>
            </div>

            {/* Right: Image Section */}
            <div className="w-1/2 h-full flex items-center justify-center">
              <img
                src={slide.image}
                alt={`Slide ${index}`}
                className="max-w-lg w-full h-[500px] object-cover rounded-lg shadow-lg" // Adjust the image size here
              />
            </div>
          </div>
        ))}
      </div>

      {/* Navigation Arrows */}
      <button
        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg"
        onClick={goToPrevious}
      >
        &#8249;
      </button>
      <button
        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg"
        onClick={goToNext}
      >
        &#8250;
      </button>

      {/* Indicators */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-3 h-3 rounded-full ${currentIndex === index ? 'bg-orange-500' : 'bg-gray-400'}`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default HeroSlider;
